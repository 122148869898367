export const GA_TRACKING_ID = 'G-9W63ML0Z2E';

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: string): void => {
  // @ts-expect-error gtag
  window.gtag('config', GA_TRACKING_ID, {
    page_path: url,
  });
};

export const exception = ({
  stack,
  componentStack,
  isDevEnv,
}: {
  stack: string | void;
  componentStack: string;
  isDevEnv: boolean;
}): void => {
  if (!isDevEnv) {
    // @ts-expect-error gtag
    window.gtag('exception', {
      exDescription: `Stack: ${stack}\nComponent Stack: ${componentStack}`,
      exFatal: false,
    });
  }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({
  action, category, label, value,
}: any): any => {
  // @ts-expect-error gtag
  window.gtag('event', action, {
    event_category: category,
    event_label: label,
    value,
  });
};
