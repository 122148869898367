import '../components/global-styles.css';
import Head from 'next/head';
import React, { ErrorInfo } from 'react';
import App, { AppInitialProps } from 'next/app';
import { pageview, exception } from '../lib/gtag';

const handleRouteChange = (url: string) => {
  pageview(url);
};

class MyApp extends App<AppInitialProps> {
  componentDidMount(): void {
    this.props.router.events.on('routeChangeComplete', handleRouteChange);
  }

  componentWillUnmount(): void {
    this.props.router.events.off('routeChangeComplete', handleRouteChange);
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    exception({
      stack: error.stack,
      componentStack: errorInfo.componentStack,
      isDevEnv: process.env.NODE_ENV === 'development',
    });
    super.componentDidCatch(error, errorInfo);
  }

  public render(): React.ReactElement {
    const {
      Component, pageProps,
    } = this.props;
    return (
      <>
        <Head>
          {/* Cool web standards */}
          <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
          <meta name="description" content="إحنا هنا بنحاول نقدملك معلومات ودروس مبنية علي خبرة سنين في تعليم الموسيقي خارج وداخل الشرق الأوسط، وبنحاول نديك خلاصة الخبرة دي بشكل ممتع وبسيط عشان نوقفك علي أرض صلب وتاخد أساس حلو تكمل بيه بأي شكل يريحك بعد كدا. عاشور جبتار معك في رحلتك الموسيقية وهتلاقي هنا رد علي أسألتك واللي يشجعك انك تاخد الأكشن وتكسر الدنيا بدل ما تكسر الجيتار" key="description" />
          <meta name="title" content="Ashour Guitar" key="title" />

          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <meta name="theme-color" content="#000" />
          <link rel="icon" type="image/png" sizes="16x16" href="/logo/black_bg/16.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/logo/black_bg/32.png" />
          <link rel="manifest" href="/manifest.json" />

          {/* Apple stuff.. yuk */}
          {/* For some reason, this is being fetched as HTTPS but in HTTP on Firefox */}
          <link rel="apple-touch-icon" sizes="180x180" href="/logo/black_bg/180.png" />
          <meta name="apple-mobile-web-app-status-bar-style" content="#000" />
          <meta name="apple-mobile-webapp-title" content="Ashour Guitar" />
          <meta name="apple-touch-fullscreen" content="yes" />
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="application-name" content="Ashour Guitar" />

          {/* MS stuff.. yuk */}
          <meta name="msapplication-TileColor" content="#000" />

          {/* SEO */}
          <meta property="og:title" key="og:title" content="Ashour Guitar" />
          <meta property="og:type" content="website" key="og:type" />
          <meta property="og:image" content="/logo/black_bg/512.png" key="og:image" />
          <meta property="og:locale" content="ar_EG" />
          <meta property="og:site_name" content="Ashour Guitar" />

          <meta property="twitter:card" content="summary_large_image" key="twitter:card" />
          <meta property="twitter:image" content="/logo/black_bg/512.png" key="twitter:image" />
        </Head>
        <Component
          {...pageProps}
        />
      </>
    );
  }
}

export default MyApp;
